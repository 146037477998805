export const registerAccount = "/registerAccount";
export const errorPermission = "/errorPermission";
export const errorPermission_2 = "/errorPermission/2";
export const networkError = "/networkError";
export const homePage = process.env.REACT_APP_FRONTEND_BASE_URL + "/home";
export const homePagev2 = process.env.REACT_APP_FRONTEND_BASE_URL + "/homev2";
export const newStructure =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/newStructure";
export const addUser = process.env.REACT_APP_FRONTEND_BASE_URL + "/addUser";
export const structureDetails =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/structure";
  export const structureDetailsV2 =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/structurev2";  
export const uploadModelPage =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/uploadBridges";

export const urlFileStorage =
  process.env.REACT_APP_BACKEND_BASE_URL + "/api/applications/files";

//units in bytes.
export const maxFileSize = 500000000;
export const maxMailKitMessageSize = 134217728;

export const JJRyanOrganisationId = 4;
export const SpencerPriceUserId = 4;
export const FernandoSilvaUserId = 6;
export const AlirezzaUserId = 9;

export const QuoteLocationOptions = [
  "QLD",
  "NSW",
  "ACT",
  "VIC",
  "TAS",
  "SA",
  "NT",
  "WA",
];

export const pageSizeArray = [
  { value: 4, name: "4" },
  { value: 8, name: "8" },
  { value: 12, name: "12" },
  { value: 16, name: "16" },
  { value: 20, name: "20" },
  { value: 24, name: "24" },
  { value: 1000, name: "All" },
];


export const overallConditions = [
  { score: 1, description: "Very Good" },
  { score: 2, description: "Good" },
  { score: 3, description: "Fair" },
  { score: 4, description: "Poor (Remedial action required)" },
  { score: 5, description: "Very Poor (Unsafe)" },
];
